@import url(https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Klee+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MenuButton {
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  left: 1%;
  border:none;
}

.TitleBar {
  background-color: pink;
  display: flex;
  align-content: left;
}
.App-header {
  background-color: rgba(0, 0, 0, 0);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bottom {
  color: black;
  background-color: white;
  min-height: 100;
  justify-content: center;
}

.Sidebar {
  text-decoration: none;
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #f6ddf3;
  will-change: opacity, transform;
  border-radius: 4px;
}

.FormRow {
  display: flex;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid white;
}

.FormRow:first-child {
  border-top: none;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  -webkit-transition: background-color 100000000s;
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 14px 15px 11px 0;
  color: grey;
  background-color: transparent;
  animation: 1ms void-animation-out;
  border: none;
}

.FormRowInput::-webkit-input-placeholder {
  color: rgb(192, 192, 192);
}

.FormRowInput:-ms-input-placeholder {
  color: rgb(192, 192, 192);
}

.FormRowInput::placeholder {
  color: rgb(192, 192, 192);
}

.FormRowInput:focus{
  outline: none;
}

